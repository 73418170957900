
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Models
import {AppCommonHealthRecord} from "@/models/app/common/health-record";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Paperclip} from "@element-plus/icons-vue";

@Options({
  props: [
    "healthRecord",
  ],
  components: {
    ElDialog,
    ElButton,
    Paperclip,
  },
})
export default class AppCoachHealthRecordsRootListFormLookupDocumentIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  moment: any = null;

  healthRecord: AppCommonHealthRecord = new AppCommonHealthRecord();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  async created(): Promise<void> {
    this.moment = moment;
  }
}
