
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonUser} from "@/models/app/common/User";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachHealthRecordsNavigationVue from "@/components/coach/health-records/navigation.vue";
import AppCoachHealthRecordsRootFilterIndexVue from "@/views/app/coach/health-records/root/filter/index.vue";
import AppCoachHealthRecordsRootListIndexVue from "@/views/app/coach/health-records/root/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachHealthRecordsNavigationVue,
    AppCoachHealthRecordsRootFilterIndexVue,
    AppCoachHealthRecordsRootListIndexVue,
  },
})
export default class AppCoachHealthRecordsRootIndexVue extends Vue {
  isLoading: boolean | null = false;

  players: Array<AppCommonUser> = [];

  $refs!: {
    AppCoachHealthRecordsRootListIndexVue: any;
  };

  getHealthRecordsByFilter(formData: any): void {
    this.$refs.AppCoachHealthRecordsRootListIndexVue.getHealthRecords(formData);
  }

  async getPlayers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/organization-members`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        role: "player",
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.players = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getPlayers();
  }
}
